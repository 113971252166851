<template>
  <div class='special-detail'>
    <div class='special-head' :class="[!topPicture || topOffset ? 'special-head-1' : ' ']">
      <van-sticky>
        <van-nav-bar @click-left='onClickLeft' @click-right='onClickRight'>
          <template #left>
            <span class='iconfont ico'>&#xe60b;</span>
          </template>
          <template #right>
            <span class='iconfont ico'>&#xe684; </span>
          </template>
          <template #title v-if='topOffset'>
            <span class='head-title' v-html="topicInfo.name"></span>
          </template>
        </van-nav-bar>
      </van-sticky>
    </div>
    <div v-if='topPicture' class='special-banner'
         :style="{ background: 'url(' + topPicture + ') no-repeat center center', 'background-size': 'cover' }"></div>
    <div class='special-main' ref='pronbit'>
      <div class='topic-title' v-if='!topOffset' v-html="topicInfo.name"></div>
      <!-- <div class="hot">
        <div class="hot-left">{{topicInfo.hots}} 热度</div>
        <div class="hot-right">+关注</div>
      </div> -->
      <div class='brief' v-if='topicInfo.description'>
        <div class='brief-left'><i class='iconfont ico'>&#xe68e;</i></div>
        <div class='brief-right'>
          <div v-html="topicInfo.description.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;')"></div>
        </div>
      </div>
      <div class='special-category' v-if='len > 1'>
        <van-tabs v-model='active' scrollspy sticky :ellipsis="false" :offset-top='40'>
          <van-tab v-for='(item, index) in topicCategory' :key='index'>
            <template slot='title'>{{ item.categoryName }}</template>
            <div class='special-category-list'>
              <div class='category-name'>
                <div class='c1'>{{ item.categoryName }}<i class='iconfont ico'></i></div>
                <CommonCard v-for='list in topicCategoryList[index]' :list='list' :key='list.id'
                            @toDetail='goDetail'></CommonCard>
                            <!-- -->
                <div class="more_btn" @click="moreDetailList(topicCategoryList[index],item.categoryId,index)" v-if="item.moreListBtnShow  && topicCategoryList[index].length > 4">
                  <button>展开更多</button>
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <div class='special-category' v-if='len == 1'>
        <div class='special-category-list'>
          <div class='category-name'>
            <div class='c1'>{{ this.topicCategory[0].categoryName }} <i class='iconfont ico'></i></div>
            <CommonCard v-for='list in topicCategoryList[0]' :list='list' :key='list.id'
                        @toDetail='goDetail'></CommonCard>
            <div class="more_btn" @click="moreDetailList(topicCategoryList[0],topicCategory[0].categoryId,0)" v-if="topicCategory[0].moreListBtnShow && topicCategoryList[0].length > 4">
              <button>展开更多</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出层 -->
    <van-share-sheet @select='selectTag' v-model='showShare' :options='options' />
  </div>
</template>

<script>
export default {
  components: {
    CommonCard: () => import('@/views/home/CommonCard')
  },
  props: ['id', 'channelId'],
  /*metaInfo() {
    return {
      title: this.topicInfo.name ? this.topicInfo.name : ''
      /!*title:'凯迪网-主流声音，全球知名华人互动媒体'*!/
    }
  },*/
  data() {
    return {
      topicInfo: [],
      topPicture: '',
      topicCategory: [],
      topicCategoryList: [],
      active: 0,
      topOffset: false,
      len: null,
      images: [],
      imagesTitle: '',
      index: 0,
      showShare: false,
      options: [
        [
          { name: '微信', icon: 'wechat' },
          { name: 'QQ', icon: 'qq' }
        ]
      ]
    }
  },
  created() {
    this.queryTopicDetails()
  },
  async mounted() {
    // 滚动条的获取
    window.addEventListener('scroll', this.handleScroll, true)
    let _this = this
    window.onscroll = function() {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      if (scrollTop + windowHeight == scrollHeight) {
        _this.active = _this.len - 1
      }
    }

    //  微信二次分享
    let params = `?channelId=${this.channelId}&topicId=${this.id}`
    let { data: res } = await this.$api.queryTopicDetail(params)
    let sharedata = {}
    if (res.code === 200) {
      sharedata = res.data
      sharedata.name = `【凯迪专题】${sharedata.name}`
      sharedata.description = sharedata.list[0].title
      sharedata.firstPicture = sharedata.firstPicture ? res.data.firstPicture : this.$baseInfo.LOGO_IMG
    }
    // 全局混入函数
    this.setWeChatShare({
      url: window.location.href,
      title: sharedata.name,
      description: sharedata.description,
      friendImg: sharedata.firstPicture ? sharedata.firstPicture : this.$baseInfo.LOGO_IMG,
      momentsImg: sharedata.firstPicture ? sharedata.firstPicture : this.$baseInfo.MOMENTS_LOGO_IMG
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    // 分享
    selectTag(tag) {
      if (tag.name === 'QQ') {
        this.qzone()
      } else if (tag.name === '微博') {
        this.weiShare()
      } else {
        this.$toast({
          type: 'html',
          message: '<p>使用浏览器的分享功能，把这</p><p style="text-align:left;">个专题分享出去</p>'
        })
      }
      this.showShare = false
    },
    // qq分享
    qzone() {
      let url = location.href
      var shareqqzonestring =
        'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' + url + '&title=' + this.topicInfo.name + '&desc=' + '【分享来之凯迪网@凯迪网】' + '&summary=&site=&pics='
      window.open(shareqqzonestring)
      window.open(shareqqzonestring, 'newwindow', 'height=100, width=400, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no')
      //此处分享链接内无法携带图片
      // var pic = 'https://tukuimg.bdstatic.com/scrop/18c685f7bcf354bc801204d2367e6088.gif'
      // var url = 'https://tmobile.9kd.com/note/24736'
      // var param = {
      //   url: url || window.location.href,
      //   desc: '天天', /*分享理由*/
      //   title: '哈哈哈' || '', /*分享标题(可选)*/
      //   summary: 'xx',/*分享描述(可选)*/
      //   pics: pic || '',/*分享图片(可选)*/
      //   flash: '', /*视频地址(可选)*/
      //   site: '' /*分享来源 (可选) */
      // };
      // var s = [];
      // for (var i in param) {
      //   s.push(i + '=' + encodeURIComponent(param[i] || ''));
      // }
      // var targetUrl = "http://connect.qq.com/widget/shareqq/iframe_index.html?" + s.join('&');
      // window.open(targetUrl);
    },
    weiShare() {
      // let url = location.href;
      // let url = 'https://3g.163.com/dy/article/FQ3EH4ED0514BE2Q.html?clickfrom=index2018_news_newslist&s=163&w=1&f=wb#offset=4';
      // var sharesinastring =
      //   "http://v.t.sina.com.cn/share/share.php?title=" +
      //   "凯迪网" + this.noteInfo.title +
      //   "&url=" +
      //   url +
      //   "&content=utf-8&sourceUrl=" +
      //   url +
      //   "&pic=" +
      //   "";
      // window.open(
      //   sharesinastring,
      //   "newwindow",
      //   "height=100, width=400, top=0,left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no"
      // );
      var pic = 'https://tukuimg.bdstatic.com/scrop/18c685f7bcf354bc801204d2367e6088.gif'
      var url = 'https://9kd.com/'

      const share = {
        title: '分享标题',
        image_url: pic,
        share_url: url
      }
      window.open(
        'https://service.weibo.com/share/share.php?url=' +
        encodeURIComponent(share.share_url) +
        '&title=' +
        share.title +
        '&appkey=4129988944&&ralateUid=1744259092&pic=' +
        share.image_url +
        '&searchPic=true'
      )

      return

      url = encodeURIComponent(url)
      var param = {
        url: url || window.location.href,
        type: '3',
        count: '1' /** 是否显示分享数，1显示(可选)*/,
        appkey: '4129988944' /** 您申请的应用appkey,显示分享来源(可选)*/,
        title: '你好微博。。。' /** 分享的文字内容(可选，默认为所在页面的title)*/,
        pic: pic || '' /**分享图片的路径(可选)*/,
        ralateUid: '' /**关联用户的UID，分享微博会@该用户(可选)*/,
        rnd: new Date().valueOf()
      }
      var temp = []
      for (var p in param) {
        temp.push(p + '=' + encodeURIComponent(param[p] || ''))
      }
      var targetUrl = 'http://service.weibo.com/share/share.php?' + temp.join('&')
      window.open(targetUrl, 'sinaweibo', 'height=430, width=400')
    },
    // 监听滚动条
    handleScroll() {
      if (this.$refs.pronbit.getBoundingClientRect().top < 30) {
        this.topOffset = true
      } else {
        this.topOffset = false
      }
    },

    // 查询文章详情
    async goDetail(id, articleType, content) {
      if (articleType === 6) {
        this.$router.push({
          path: '/note/' + id,
          query: {
            articleType: 6
          }
        })
      } else if (articleType === 4) {
        window.open(content)
      } else {
        this.$router.push({
          path: '/note/' + id
        })
      }
    },

    // 查询专题详情
    async queryTopicDetails() {
      let params = `?channelId=${this.channelId}&topicId=${this.id}`
      let { data: res } = await this.$api.queryTopicDetail(params)
      if (res.code !== 200) {
        this.$router.push('/')
        return
      }
      this.topicInfo = res.data;
      this.topicInfo.name = this.topicInfo.name.replace(/\s/g, '&nbsp;')
      this.topPicture = res.data.topPicture
      this.topicCategory = res.data.categoryList
      // 添加每个类目控制“展开更多按钮”
      this.topicCategory.forEach(category=>{
        category.moreListBtnShow = true
      })
      this.len = res.data.categoryList.length
      let sortArry = res.data.list
      let newArry = []
      // 根据tagId将数据处理成类目分类形式
      sortArry.forEach((item) => {
        let array = newArry[item['tagId']] || []
        array.push(item)
        newArry[item['tagId']] = array
      })
      let arr = Object.values(newArry)
      this.topicCategoryList = this.topicCategoryList.concat(arr)
    },
    //查询更多
   async moreDetailList(listArr,categoryId,idx){
      let lastId = listArr[listArr.length-1].id
      let params = {
        categoryId,
        lastId,
        topicId:this.id,
        channelId:this.channelId
      }
      let {data:res} = await this.$api.specialDetailLater(params)
      if(res.code == 321){
        this.topicCategory[idx].moreListBtnShow = false
        // this.$forceUpdate()
        // 根级别的响应式属性Vue.set(object, key, value)
        this.$set(this.topicCategoryList[idx],'moreListBtnShow',false)
        return
      }
      if(res.code !== 200) return this.$toast.fail('获取内容失败');
      if(res.data && res.data.length < 10) {
        this.topicCategory[idx].moreListBtnShow = false
        this.$set(this.topicCategoryList[idx],'moreListBtnShow',false)
      }
      res.data.forEach(item=>{
        this.topicCategoryList[idx].push(item)
      })
      // this.topicCategoryList[idx] = this.topicCategoryList[idx].concat(res.data)
      // this.$forceUpdate()
      // this.$set(this.topicCategoryList,this.topicCategoryList[idx],this.topicCategoryList[idx].concat(res.data))
      // 利用索引直接设置一个项使用 Vue.set(vm.items, indexOfItem, newValue)
      // this.$set(this.topicCategoryList,idx,this.topicCategoryList)
    },
    onClickLeft() {
      this.$router.push({
        path: '/'
      })
    },

    onClickRight() {
      this.showShare = true
    },

    onChange(index) {
      this.index = index
    }
  }
}
</script>

<style lang='less'>
.special-detail {
  font-family: PingFang SC, PingFang SC-Regular;
  font-size: 13px;

  .special-head {
    height: 46px;
    width: 100%;
    overflow: hidden;

    .van-nav-bar {
      background: rgba(0, 0, 0, 0);

      .ico {
        font-size: 25px;
        color: #fff;
      }
    }
  }

  .special-head-1 {
    height: 46px;
    width: 100%;
    overflow: hidden;

    .van-nav-bar {
      background: #fff;

      .ico {
        font-size: 25px;
        color: #3a3e45;
      }
    }
  }

  .special-banner {
    width: 100%;
    height: 160px;
    margin-top: -46px;
  }

  .special-main {
    background: #ffffff;
    margin-top: -10px;
    border-radius: 15px 15px 0px 0px;
    padding-top: 16px;
    box-sizing: border-box;

    .topic-title {
      padding: 0 14px 0;
      font-size: 20px;
      font-weight: bold;
      text-align: left;
      color: #3a3e45;
      letter-spacing: 1px;
    }

    .hot {
      height: 26px;
      color: #9099a6;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 14px;

      .hot-right {
        width: 54px;
        height: 24px;
        background: #F7321C;
        border-radius: 12px;
        line-height: 24px;
        text-align: center;
        color: #fff;
      }
    }

    .brief {
      box-sizing: border-box;
    //   width: 347px;
      min-height: 65px;
      background: #f3f6f9;
      border-radius: 4px;
      padding: 12px 10px 12px 12px;
      display: flex;
      align-items: center;
      margin: 14px 14px 14px;

      .brief-left {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        margin-right: 8px;

        .ico {
          font-size: 26px;
          color: #F7321C;
        }
      }

      .brief-right {
        color: #8c94a3;
        font-size: 12px;
        word-break: break-all;
        line-height: 1.5;
      }
    }

    .special-category {
      padding-bottom: 90px;
      position: relative;

      .van-tabs__line {
        background-color: #F7321C;
      }

      .van-tabs__wrap {
        padding-right: 0;
      }

      .special-category-list {
        .category-name {
          padding-top: 14px;
          font-size: 16px;
          text-align: left;
          color: #3a3e45;

          .c1 {
            padding-left: 14px;
            display: flex;

            .ico {
              margin-top: 4px;
              margin-left: 2px;
              width: 2px;
              height: 2px;
              border-radius: 50%;
              border: 2px solid #F7321C;
              color: #F7321C;
            }
          }
        }
      }
    }
  }

  .image-desc {
    width: 350px;
    height: 100px;
    color: #ffffff;
    position: relative;
    top: 550px;
    left: 10px;
  }
  .more_btn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 10px 0;
    font-size: 13px;
    color: #F7321C;
    line-height: 40px;
    letter-spacing: 1px;
    button {
      width: 212px;
      height: 40px;
      border: 1px solid #F7321C;
      border-radius: 20px;
      background: #ffffff;
    }
  }
}
</style>
