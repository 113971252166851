<template>
  <div class="topic-list">
    <van-nav-bar @click-left="$router.back()">
      <template #title>新闻资讯</template>
      <template #left>
        <span class="iconfont ico">&#xe60b;</span>
      </template>
    </van-nav-bar>
    <div class="news-list">
      <!-- 引入组件 -->
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <CommonCard v-for="list in lists" :list="list" :key="list.id" @toDetail="goDetail"></CommonCard>
      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      searchInfo: {
        categoryID: this.lid,
        limit: 10,
        page: 1,
        product: this.pid,
      },
      lists: [],
    }
  },
  props: ['lid', 'pid'],
  components: {
    CommonCard: () => import('@/views/home/CommonCard'),
  },
  methods: {
    goDetail(id, articleType) {
      if (articleType === 5) {
        this.$router.push({
          name: 'special',
          params: {
            id,
          },
        })
      } else {
        this.$router.push({
          name: 'noteDetail',
          params: {
            id,
          },
        })
      }
    },
    async onLoad() {
      let { data: res } = await this.$api.articles(this.searchInfo)
      if (res.data.length === 0) {
        this.finished = true
        return
      }
      if (res.code !== 200) return this.$toast.fail(res.msg)
      this.lists = this.lists.concat(res.data)
      this.searchInfo.page++

      // 加载状态结束
      this.loading = false
    },
  },
}
</script>

<style lang="less">
.topic-list {
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #3a3e45;
  letter-spacing: 1px;
  margin-bottom: 90px;
  font-size: 15px;
  .van-nav-bar {
    height: 44px;
    background: #c48657;
  }
  .van-nav-bar__title {
    color: #ffffff;
  }
  .ico {
    color: #ffffff;
    font-size: 25px;
  }
}
</style>