<template>
  <div class="topic-detail">
    <van-nav-bar @click-left="$router.push('/topic')">
      <template #title>{{ title }}123</template>
      <template #left>
        <span class="iconfont ico">&#xe60b;</span>
      </template>
    </van-nav-bar>
    <div class="news-content" v-html="content"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '',
      content: ''
    }
  },
  props: ['id', 'name'],
  created() {
    if (this.name == 1) {
      this.title = '启动仪式'
    } else if (this.name == 2) {
      this.title = '作品征集'
    } else {
      this.$router.push('/')
    }
    this.queryone()
  },
  methods: {
    // 查询文章详情
    async queryone() {
      let { data: res } = await this.$api.queryone(this.id);
      if (res.code !== 200) {
        this.$router.push('/')
        return
      }
      this.content = res.data.content
    }
  },
}
</script>
<style lang="less">
.topic-detail {
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #3a3e45;
  letter-spacing: 1px;
  margin-bottom: 90px;
  font-size: 15px;
  .van-nav-bar {
    height: 44px;
    background: #c48657;
  }
  .van-nav-bar__title {
    color: #ffffff;
  }
  .ico {
    color: #ffffff;
    font-size: 25px;
  }
}
.news-content {
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  padding: 22px;
  font-size: 15px;
  text-align: left;
  color: #414449;
  line-height: 35px;
  letter-spacing: 1px;
  img {
    width: 100%;
    height: auto;
  }
}
</style>
